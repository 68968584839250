
import Vue from "vue";
import store from "@/store";
import API from "@/api/API";
import { mask } from "vue-the-mask";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Create",

  components: {
    ata: () => import("../components/order_types/ATA/Edit.vue"),
    aaij: () => import("../components/order_types/AAIJ/Edit.vue"),
    cert: () => import("../components/order_types/CERT/Edit.vue"),
    eval: () => import("../components/order_types/EVAL/Edit.vue"),
    exp: () => import("../components/order_types/EXP/Edit.vue")
  },

  directives: { mask },

  data: () => ({
    rules,
    model: {} as any,
    loading: false as boolean,
    changeStatusDialog: false as boolean,
    editComponents: {
      1: "ata",
      2: "aaij",
      3: "cert",
      4: "eval",
      5: "exp"
    },
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.edit",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    orderStatuses: [] as Array<any>,
    selects: {
      statuses: [] as Array<any>
    } as any,
    comment: "" as string,
    showChangeStatus: false as boolean,
    selectedStatus: null as any,
    errorMessages: {} as any,
    loadingChangeStatus: false as any,
    experts: [] as Array<any>,
    status: {} as any
  }),

  computed: {
    expanded() {
      return this.$store.getters["navigation/expanded"];
    },

    left() {
      if (this.expanded) {
        return "257px";
      }

      if (this.$vuetify.breakpoint.mdAndUp && !this.expanded) {
        return "65px";
      }

      return 0;
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    isAdmin() {
      return this.currentRole.alias === "admin";
    },
    isExp() {
      return this.model.order.service_type_id === 5;
    },
    isAta() {
      return this.model.order.service_type_id === 1;
    }
  },

  async mounted() {
    await this.loadData();
    await this.removeNotExpStatuses();
  },

  methods: {
    async loadData() {
      this.loading = true;
      try {
        const params = {} as any;
        if (this.$route.query.hasOwnProperty("additions")) {
          params.additions = this.$route.query.additions;
        }

        const item = await API.orders().getForEdit(
          Number(this.$route.params.id),
          params
        );

        const statuses = await this.$API.orders().getStatuses();

        for (const item of statuses) {
          this.selects.statuses[item.alias] = item;
        }
        this.orderStatuses = await this.$API
          .orders()
          .getOrderStatuses(this.$route.params.id);

        const parent_documents = item.parent_documents?.map((item: any) => ({
          value: item.id,
          text: item.number
        }));

        this.$nextTick(() => {
          this.fullLoaded = true;
          this.model = {
            ...item,
            parent_documents
          };
        });
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    showStatusDialog(): void {
      this.changeStatusDialog = !this.changeStatusDialog;
    },
    async changeOrderStatus(): Promise<void> {
      this.loadingChangeStatus = true;
      try {
        if (this.selectedStatus.status) {
          await this.$API
            .orders()
            .changeOrderStatus(this.model.order.id, this.selectedStatus);
          this.changeStatusDialog = false;
          this.closeChangeStatusDialog();
          await this.loadData();
          await this.removeNotExpStatuses();
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loadingChangeStatus = false;
    },
    async cloneOrder(id: string): Promise<void> {
      try {
        await this.$API.orders().cloneOrder(id);
        this.$emit("update");
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    closeChangeStatusDialog(): any {
      this.showChangeStatus = false;
    },
    toggleChangeStatusDialog(status: any = null): any {
      const isStatusConfirmed = [this.status.alias, status.alias].every(
        alias => alias === "confirmed"
      );

      if (this.isExp && !this.experts.length && isStatusConfirmed) {
        (this.$refs.component as Vue).openAllocationTab();
        this.$store.dispatch(
          "alert/showError",
          this.$t("orders.modals.alert.select_expert")
        );
        return;
      }
      this.showChangeStatus = !this.showChangeStatus;
      this.selectedStatus = status;
    },
    removeNotExpStatuses() {
      if (this.isExp) {
        if (this.currentRole.alias !== "expert" && this.currentRole.alias !== "admin") {
          this.orderStatuses.forEach((item, index, array) => {
            if (item.alias === "signing") {
              array.splice(index, 1);
            }
            if (item.alias === "signed") {
              array.splice(index, 1);
            }
          });
        }
        if (this.model.order.status.alias !== "checked") {
          this.orderStatuses.forEach((item, index, array) => {
            if (item.alias === "signed") {
              array.splice(index, 1);
            }
          });
        }
        if (this.currentRole.alias !== "subdivision_head" && this.currentRole.alias !== "admin") {
          this.orderStatuses.forEach((item, index, array) => {
            if (item.alias === "checked") {
              array.splice(index, 1);
            }
          });
        }
        this.orderStatuses.forEach((item, index, array) => {
          if (item.alias === "check_beneficiary") {
            array.splice(index, 1);
          }
        });
        this.orderStatuses.forEach((item, index, array) => {
          if (item.alias === "checked_beneficiary") {
            array.splice(index, 1);
          }
        });
      }
    },
    getData(obj: any): void {
      this.experts = obj.experts;
      this.status = obj.status;
    }
  }
});
